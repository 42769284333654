import React, { useState } from "react";
import NormalBox from "./NormalBox";

const Spell = ({ setScene, setMenu, setWard }) => {
    const [selectedSpell, setSelectedSpell] = useState();
    //const [nameSpell, setNameSpell] = useState();

    const spells = [
        {
            key: "homepage",
            value: "ホムーペ",
            html: (
                <>
                    <p>クマちゃんは　{selectedSpell}　をとなえた！</p>
                    <p>
                        ワタナベフラワーオフィシャルホームページに移動しますか？
                    </p>
                    <ul>
                        <li>
                            <a
                                href="https://watanabeflower.com/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                はい (外部サイト)
                            </a>
                        </li>
                        <li onClick={() => setMenu("")}>いいえ</li>
                    </ul>
                </>
            ),
        },
        {
            key: "credit",
            value: "クレジト",
            html: (
                <>
                    <p>クマちゃんは　{selectedSpell}　をとなえた！</p>
                    <p>
                        この特設ページは、みんなの協力で出来ていることが分かった！！
                        <br />
                        ホメて！！
                    </p>
                    <ul>
                        <li>
                            <a
                                href="https://watanabeflower.com/profile/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                BGMアレンジ / イクロー
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://bambicreate.com/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                キャラクターデザイン / バンビクリエイトスタジオ
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://mahounouta.com/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                ドッター(ドット絵師) / おはな
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://figaro-web.com/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                プログラム・他 / やっちん(フィガロウェブ)
                            </a>
                        </li>
                    </ul>
                    <div
                        className="cursor-triangle"
                        onClick={() => setMenu("")}
                    ></div>
                </>
            ),
        },
        {
            key: "escape",
            value: "エスケプ",
            html: (
                <>
                    <p>クマちゃんは　{selectedSpell}　をとなえた！</p>
                    <p>
                        外部リンクの一覧があらわれた！
                        <br />
                        ワタナベフラワーの事をもっと知ろう!!
                    </p>
                    <ul>
                        <li>
                            <a
                                href="https://watanabeflower.com/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                ワタナベフラワー公式HP
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://note.com/kumagaitatsurou/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                神戸へおいで Note
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/channel/UCRSvL6_15bgJ5zAmyNQhdAA"
                                rel="noreferrer"
                                target="_blank"
                            >
                                ワタナベフラワー公式Youtube
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/wf87_info"
                                rel="noreferrer"
                                target="_blank"
                            >
                                ワタナベフラワー公式Twitter
                            </a>
                        </li>
                    </ul>
                    <p>応援よろしく!!</p>
                    <div
                        className="cursor-triangle"
                        onClick={() => setMenu("")}
                    ></div>
                </>
            ),
        },
    ];

    const onClickedspellList = (props) => {
        console.log(props.target.id);
        setSelectedSpell(props.target.innerText);

        if (props.target.id === "homepage") {
        }
    };

    const checkSpell = (value) => {
        if (value === selectedSpell) {
            return "selected";
        }
    };

    const spellList = spells.map((obj) => {
        return (
            <li
                className={checkSpell(obj.value)}
                onClick={onClickedspellList}
                key={obj.key}
                id={obj.key}
            >
                {obj.value}
            </li>
        );
    });

    return (
        <NormalBox boxClassName={"talk-box"} h2Text="じゅもん">
            {selectedSpell === "ホムーペ" ? (
                spells[0].html
            ) : selectedSpell === "クレジト" ? (
                spells[1].html
            ) : selectedSpell === "エスケプ" ? (
                spells[2].html
            ) : (
                <>
                    <ul className="ward-select">{spellList}</ul>
                    <div
                        className="cursor-triangle"
                        onClick={() => setMenu("")}
                    ></div>
                </>
            )}
        </NormalBox>
    );
};

export default Spell;
