import IntroButtonBox from "../components/IntroButtonBox";
import IntroCloudImg from "../components/IntroCloudImg";

const Intro = ({ audio, setScene, soundOn }) => {
    return (
        <div className="intro-box">
            <IntroCloudImg />
            <IntroButtonBox {...{ audio, setScene, soundOn }} />
        </div>
    );
};

export default Intro;
