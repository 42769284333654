export const wards = () => {
    return [
        { key: "higashinadaku", value: "東灘区" },
        { key: "nadaku", value: "灘区" },
        { key: "chuoku", value: "中央区" },
        { key: "hyogoku", value: "兵庫区" },
        { key: "nagataku", value: "長田区" },
        { key: "sumaku", value: "須磨区" },
        { key: "tarumiku", value: "垂水区" },
        { key: "nishiku", value: "西区" },
        { key: "kitaku", value: "北区" },
    ];
};
