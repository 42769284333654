import useWindowDimensions from "../hooks/useWindowDimensions";

const IntroCloudImg = () => {
    const windowSize = useWindowDimensions();
    //console.log(windowSize.width);

    let cloudWidth = 0;
    let cloudHeight = 0;
    let cloudLeft = 0;
    if (windowSize.width >= 1280) {
        cloudWidth = 2560;
        cloudHeight = 98;
        cloudLeft = 1280;
    } else {
        cloudWidth = windowSize.width * 2;
        cloudHeight = windowSize.width * 0.077;
        cloudLeft = windowSize.width;
    }

    const CloudAction = ( //二回書かれるの不細工
        <style>
            {`
            @keyframes cloudMove {
                0% {
                    left: 0;
                }

                100% {
                    left: -` +
                cloudLeft +
                `px;
                }
            }
            `}
        </style>
    );

    const classValue = {
        width: cloudWidth + "px",
        height: cloudHeight + "px",
        animationName: "cloudMove",
    };

    return (
        <>
            {CloudAction}
            <div className="cloud" style={classValue}></div>
            <div className="cloud" style={classValue}></div>
        </>
    );
};

export default IntroCloudImg;
