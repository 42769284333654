const Header = ({ ward, menu, setMenu, setShopBoxFlag }) => {
    //const [clickedMenu, setClickedMenu] = useState();

    const selectedMenu = (value) => {
        if (value === menu) {
            return "selected";
        }
    };

    const onClickedSelectedMenu = (props) => {
        setShopBoxFlag("");
        setMenu(props.target.innerText);
    };

    return (
        <header>
            <div className="main-menu">
                <h2>{ward} コマンド</h2>
                <ul>
                    <li
                        className={selectedMenu("はなす")}
                        onClick={onClickedSelectedMenu}
                    >
                        はなす
                    </li>
                    {!ward ? (
                        <li
                            className={selectedMenu("おしらせ")}
                            onClick={onClickedSelectedMenu}
                        >
                            おしらせ
                        </li>
                    ) : (
                        <li
                            className={selectedMenu("おみせ")}
                            onClick={onClickedSelectedMenu}
                        >
                            おみせ
                        </li>
                    )}
                    <li
                        className={selectedMenu("いどう")}
                        onClick={onClickedSelectedMenu}
                    >
                        いどう
                    </li>
                    <li
                        className={selectedMenu("じゅもん")}
                        onClick={onClickedSelectedMenu}
                    >
                        じゅもん
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
