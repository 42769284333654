import mapHigashinadaku from "../assets/img/map-higashinadaku-sp.png";
import mapNadaku from "../assets/img/map-nadaku-sp.png";
import mapChuoku from "../assets/img/map-chuoku-sp.png";
import mapHyogoku from "../assets/img/map-hyogoku-sp.png";
import mapNagataku from "../assets/img/map-nagataku-sp.png";
import mapSumaku from "../assets/img/map-sumaku-sp.png";
import mapTarumiku from "../assets/img/map-tarumiku-sp.png";
import mapNishiku from "../assets/img/map-nishiku-sp.png";
import mapKitaku from "../assets/img/map-kitaku-sp.png";
import mapKobe from "../assets/img/map-kobe-sp.png";
import mapBase from "../assets/img/map-base-sp.png";

import CharacterImg from "./CharacterImg";
import imgKuma from "../assets/img/character-kuma.png";
import imgMusa from "../assets/img/character-musa.png";
import imgIkuro from "../assets/img/character-ikuro.png";

const AreaImg = ({ ward, styleAreaBox }) => {
    const checkWardCss = (mapWardName) => {
        //console.log("現在の区は" + ward);
        //console.log("画像から読み込んだ" + mapWardName);
        if (ward === mapWardName)
            return {
                display: "block",
                animation: "mapAnime .3s linear",
            };
    };

    const mapData = [
        { key: "東灘区", value: mapHigashinadaku },
        { key: "灘区", value: mapNadaku },
        { key: "中央区", value: mapChuoku },
        { key: "兵庫区", value: mapHyogoku },
        { key: "長田区", value: mapNagataku },
        { key: "須磨区", value: mapSumaku },
        { key: "垂水区", value: mapTarumiku },
        { key: "西区", value: mapNishiku },
        { key: "北区", value: mapKitaku },
    ];

    const spellList = mapData.map((obj) => {
        return (
            <img
                key={obj.key}
                className="img-map"
                style={(styleAreaBox, checkWardCss(obj.key))}
                src={obj.value}
                alt={obj.key}
            />
        );
    });

    return (
        <>
            {!ward ? (
                <>
                    <CharacterImg
                        imgCharacter={imgKuma}
                        defaultPotision={{ x: 5, y: 7 }}
                    />
                    <CharacterImg
                        imgCharacter={imgMusa}
                        defaultPotision={{ x: 10, y: 10 }}
                    />
                    <CharacterImg
                        imgCharacter={imgIkuro}
                        defaultPotision={{ x: 14, y: 14 }}
                    />
                </>
            ) : null}
            {spellList}
            <img
                className="img-map"
                style={(styleAreaBox, checkWardCss(undefined))}
                src={mapKobe}
                alt="神戸市"
            />
            <img
                id="img-map-kobe"
                className="img-map"
                style={styleAreaBox}
                src={mapBase}
                alt=""
            />
        </>
    );
    // }
};

export default AreaImg;
