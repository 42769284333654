import shopIcon1 from "../assets/img/shop-icon-1.png";
import shopIcon2 from "../assets/img/shop-icon-2.png";
import shopIcon3 from "../assets/img/shop-icon-3.png";
import shopIcon4 from "../assets/img/shop-icon-4.png";
import shopIcon5 from "../assets/img/shop-icon-5.png";
import shopIcon6 from "../assets/img/shop-icon-6.png";
import shopIcon7 from "../assets/img/shop-icon-7.png";
import shopIcon8 from "../assets/img/shop-icon-8.png";
import shopIcon9 from "../assets/img/shop-icon-9.png";
import shopIcon10 from "../assets/img/shop-icon-10.png";
import shopIcon11 from "../assets/img/shop-icon-11.png";
import shopIcon12 from "../assets/img/shop-icon-12.png";
import shopIcon13 from "../assets/img/shop-icon-13.png";
import shopIcon14 from "../assets/img/shop-icon-14.png";
import shopIcon15 from "../assets/img/shop-icon-15.png";
import shopIcon16 from "../assets/img/shop-icon-16.png";
import shopIcon17 from "../assets/img/shop-icon-17.png";
import shopIcon18 from "../assets/img/shop-icon-18.png";
import shopIcon19 from "../assets/img/shop-icon-19.png";
import shopIcon20 from "../assets/img/shop-icon-20.png";
import shopIcon21 from "../assets/img/shop-icon-21.png";
import shopIcon22 from "../assets/img/shop-icon-22.png";
import shopIcon23 from "../assets/img/shop-icon-23.png";
import shopIcon24 from "../assets/img/shop-icon-24.png";
import shopIcon25 from "../assets/img/shop-icon-25.png";
import shopIcon26 from "../assets/img/shop-icon-26.png";
import shopIcon27 from "../assets/img/shop-icon-27.png";

const AreaShops = ({ ward, setShopBoxFlag, wardCsvData, setMenu }) => {
    const ShopImg = ({ data, ward }) => {
        const widthCheck = data[8] === "1" ? 10 : 5;
        const heightCheck = data[9] === "1" ? 8.334 : 4.167;

        const style = {
            left: data[3] * 5 + "%",
            top: data[4] * 4.167 + "%",
            width: widthCheck + "%",
            height: heightCheck + "%",
        };

        const onClickShop = () => {
            setShopBoxFlag(data[1]);
            setMenu("");
        };

        const shopIconName = [
            shopIcon1,
            shopIcon2,
            shopIcon3,
            shopIcon4,
            shopIcon5,
            shopIcon6,
            shopIcon7,
            shopIcon8,
            shopIcon9,
            shopIcon10,
            shopIcon11,
            shopIcon12,
            shopIcon13,
            shopIcon14,
            shopIcon15,
            shopIcon16,
            shopIcon17,
            shopIcon18,
            shopIcon19,
            shopIcon20,
            shopIcon21,
            shopIcon22,
            shopIcon23,
            shopIcon24,
            shopIcon25,
            shopIcon26,
            shopIcon27,
        ];

        return (
            <>
                {data[6] === ward && (
                    <img
                        onClick={onClickShop}
                        className="shop-icon-1"
                        src={shopIconName[data[5]]}
                        alt={data[1]}
                        style={style}
                    />
                )}
            </>
        );
    };

    return (
        <div className="shops-area-box">
            {wardCsvData &&
                wardCsvData.map((data, index) => (
                    <ShopImg key={index} {...{ data, ward }} />
                ))}
        </div>
    );
};

export default AreaShops;
