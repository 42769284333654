import { useState, useEffect, useRef } from "react";
import logo from "../assets/img/logo.png";
import IntroRepeat from "../components/IntroRepeat";
import changeSong from "../utils/changeSong";
import { Transition } from "react-transition-group";

const IntroButtonBox = ({ audio, setScene, soundOn }) => {
    const LogoImg = () => (
        <img
            src={logo}
            alt="ワタナベフラワー 神戸へおいでロゴ"
            className="logo"
        />
    );
    const [introPopup, setIntroPopup] = useState(false);
    const firstUpdate = useRef(true);

    const nodeRef = useRef(null); //

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        setTimeout(() => {
            setIntroPopup(false);
        }, 3000);

        return clearTimeout();
    }, [introPopup]);

    const changeIntroPopup = () => {
        setIntroPopup(true);
    };

    const duration = 2000; //ロゴが上がってくる速度

    //トランジションのスタイル4種類を定義(使わないものは省略可能)
    const transitionStyle = {
        entering: {
            transition: `all ${duration}ms linear`,
            top: "40%",
        },
        entered: {
            //transition: "all 0s ease",
            top: "40%",
        },
        // ,
        // exiting: {
        //     transition: "all 1s ease",
        //     transform: "translateY(0)",
        //     backgroundColor: "blue",
        // },
        // exited: {
        //     transition: "all 1s ease",
        //     transform: "translateY(0)",
        //     backgroundColor: "gray",
        // },
    };

    //マウントの状態を管理
    const [mount, setMount] = useState(false);
    const [introButton, setIntroButton] = useState(false);

    useEffect(() => {
        //setMount(true);

        //効かないので取り急ぎ
        setTimeout(() => {
            setMount(true);
        }, 1000);

        return clearTimeout();
    }, [setMount]);

    const callBacks = {
        //onEnter: () => console.log("enterです"),
        onEntered: () => {
            setIntroButton(true);
        },
        //onEntered: () => console.log("enteredです"),
        // onExit: () => console.log("exitです"),
        // onExited: () => console.log("exitedです"),
    };

    return (
        <>
            {introPopup === true ? <IntroRepeat /> : ""}
            <Transition
                nodeRef={nodeRef}
                in={mount}
                timeout={duration}
                appear={true}
                {...callBacks}
            >
                {(state) => (
                    <div id="intro-logo-box" style={transitionStyle[state]}>
                        <LogoImg />
                        <div
                            id="intro-button-box"
                            className={
                                introButton ? "normal-box show" : "normal-box"
                            }
                        >
                            <div className="box-inner">
                                <h2>
                                    <span>こうべ に おいで？</span>
                                </h2>
                                <ul>
                                    <li
                                        onClick={() => {
                                            setScene("field");
                                            changeSong(
                                                "kobestrut",
                                                audio,
                                                soundOn
                                            );
                                        }}
                                    >
                                        行～く行く行く行くぅ～!
                                    </li>
                                    <li onClick={() => changeIntroPopup()}>
                                        行かない
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </Transition>
        </>
    );
};

export default IntroButtonBox;
