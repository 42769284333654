import { useState, useEffect, useRef } from "react";
import "./assets/scss/App.scss";
import SoundSelect from "./components/SoundSelect.jsx";
import Intro from "./components/Intro.jsx";
import Field from "./components/Field.jsx";

function App() {
    const [scene, setScene] = useState("sound-select");
    const [menu, setMenu] = useState();
    const [ward, setWard] = useState();
    const [soundOn, setSoundOn] = useState(false);

    const audio = useRef(null);
    useEffect(() => {
        audio.current = new Audio();
        audio.current.repeat = true;
        audio.current.addEventListener("ended", function () {
            if (!!this.repeat) {
                this.play();
            }
        });
    }, []);

    return (
        <div className="App">
            {scene === "sound-select" ? (
                <SoundSelect {...{ audio, setScene, soundOn, setSoundOn }} />
            ) : scene === "intro" ? (
                <Intro {...{ audio, setScene, soundOn }} />
            ) : (
                <Field {...{ menu, setMenu, ward, setWard, setScene }} />
            )}
        </div>
    );
}

export default App;
