import React from "react";

const TalkText = React.memo(({ ward, setMenu }) => {
    const TalkHtml = () => {
        switch (ward) {
            case "東灘区":
                return (
                    <p>
                        「神戸へおいで」企画最後の9区目、東灘区!!
                        <br />
                        毎年お祭りに呼んでくれる愛すべき六甲アイランドに居を構え、きちんと買ったバイクで走り出す、42の夜。(昼も)
                    </p>
                );
            case "灘区":
                return (
                    <p>
                        「神戸へおいで」企画 5区目、灘区！
                        <br />
                        偶然にも?!5月末で立ち退きだった灘区の自宅に一旦戻りリフレッシュ！想いを新たに次の区へGO!!
                    </p>
                );
            case "中央区":
                return (
                    <p>
                        「神戸へおいで」企画の6区目、中央区。ホテルや観光業を盛り上げるべくホテルやタクシーから配信するなど、半年目にして新たな挑戦！
                    </p>
                );
            case "兵庫区":
                return (
                    <p>
                        「神戸へおいで」企画、2番目に住んだ街、兵庫区!!
                        <br />
                        1ヵ月まるまる緊急事態宣言で大変だったけど、和田岬の駄菓子屋さん、淡路屋に住ませてもらって沢山の楽しい(クセ強め)人たちと触れ合うことができたよ！
                    </p>
                );
            case "長田区":
                return (
                    <p>
                        「神戸へおいで」企画
                        4区目、長田区！ザ☆人情の街！と言っても過言でないくらいの街。長田区長自ら心配して連絡くれたり、とにかく人の優しさに触れられた街でした！
                    </p>
                );
            case "須磨区":
                return (
                    <p>
                        「神戸へおいで」企画、3番目に住んだ街、須磨区!!会議室在住となり、スポーツジムにお風呂をもらいに通うという破天荒な暮らしの先に見たものは…!?
                        (げ…解脱?!)
                    </p>
                );
            case "垂水区":
                return (
                    <p>
                        この「神戸へおいで」企画で最初に住んだ区が垂水区！2020年12月31日の大晦日ライブを終えて即引っ越し！2021年1月1日から住み始めた街やで！
                    </p>
                );
            case "西区":
                return (
                    <p>
                        「神戸へおいで」企画…8区目、西区。長雨にメンタルを削られるも、なでしこの湯と山田tequilaまさしに頂いた野菜で回復し、後半は沢山冒険出来ました！アリアハン!!(ありがとうの意)
                    </p>
                );
            case "北区":
                return (
                    <p>
                        「神戸へおいで」企画…7区目、北区！機動力が問われるこの大きな区を前にして武者震い。神戸市北区役所の原田さんが貸してくれた電動アシスト付き自転車、ワタナベモータースT-BIRDさんが貸してくれたバイクで縦横無尽に駆け回る！でも借りてばっかりじゃないよ、恩はでっかく返すからね！
                    </p>
                );
            default:
                return (
                    <>
                        <p>「神戸へおいで」特設サイトだよ！</p>
                        <p>
                            「いどう」から行きたい区を選ぶか、神戸市マップの各区をクリックしてみてね！
                        </p>
                    </>
                );
        }
    };

    return (
        <div className="text-block">
            <TalkHtml />
            <p>
                詳しくは
                <a
                    className="link-text"
                    href="https://note.com/kumagaitatsurou"
                    rel="noreferrer"
                    target="_blank"
                >
                    Noteのブログ
                </a>
                (外部サイト)を見てね！
            </p>
            <div className="cursor-triangle" onClick={() => setMenu("")}></div>
        </div>
    );
});

export default TalkText;
