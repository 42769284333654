import { useState, useEffect, useRef } from "react";

const CharacterImg = ({ imgCharacter, defaultPotision }) => {
    const [positionCharacterBg, setPositionCharacterBg] = useState(false);
    const [positionCharacterX, setPositionCharacterX] = useState(
        defaultPotision.x
    );
    const [positionCharacterY, setPositionCharacterY] = useState(
        defaultPotision.y
    );
    const refPositionCharacterX = useRef(positionCharacterX);
    const refPositionCharacterY = useRef(positionCharacterY);
    useEffect(
        () => (refPositionCharacterX.current = positionCharacterX),
        [positionCharacterX]
    );
    useEffect(
        () => (refPositionCharacterY.current = positionCharacterY),
        [positionCharacterY]
    );

    useEffect(() => {
        /*

        X軸はスマホ20、Y軸はスマホ24
        0 = X+ , 1 = X- , 2 = Y+ , 3 = Y- , 4 = 動かない
        枠を超えない
        */
        const randomNum = () => Math.floor(Math.random() * 5);

        const positionInterval = setInterval(() => {
            setPositionCharacterBg(
                (positionCharacterBg) => !positionCharacterBg
            );
        }, 500);

        const imgInterval = setInterval(() => {
            //console.log(randomNum());
            const resultNum = randomNum();

            // console.log('X:' + refPositionCharacterX.current + ' Y:' + refPositionCharacterY.current);

            if (resultNum === 0 && refPositionCharacterX.current <= 18) {
                setPositionCharacterX(
                    (positionCharacterX) => positionCharacterX + 1
                );
            } else if (resultNum === 1 && 2 <= refPositionCharacterX.current) {
                setPositionCharacterX(
                    (positionCharacterX) => positionCharacterX - 1
                );
            } else if (resultNum === 2 && refPositionCharacterY.current <= 17) {
                setPositionCharacterY((positionCharacterY) => {
                    return positionCharacterY + 1;
                });
            } else if (resultNum === 3 && 2 <= refPositionCharacterY.current) {
                setPositionCharacterY((positionCharacterY) => {
                    return positionCharacterY - 1;
                });
            }
        }, 500);

        return () => {
            //メモリリーク対策
            clearInterval(imgInterval);
            clearInterval(positionInterval);
            setPositionCharacterBg(false);
        };
    }, []);

    const characterBgPosition = positionCharacterBg ? "0%" : "100%";

    const characterPosition = {
        backgroundImage: `url(${imgCharacter})`,
        left: positionCharacterX * 5 + "%",
        top: positionCharacterY * 4.166 + "%",
        backgroundPosition: characterBgPosition,
    };
    return (
        <div style={characterPosition} className="character-img">
            メンバー
        </div>
    );
};

export default CharacterImg;
