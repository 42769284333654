import { useState } from "react";
import Header from "./Header.jsx";
import Move from "./Move";
import Status from "./Status";
import Talk from "./Talk";
import Area from "./Area";
import Spell from "./Spell";
import ShopList from "./ShopList";
import OpeningTalk from "./OpeningTalk";
import OpeningSkip from "./OpeningSkip";

const Field = ({ menu, setMenu, ward, setWard, setScene }) => {
    const [openignTalk, setOpeningTalk] = useState(true);
    const [shopBoxFlag, setShopBoxFlag] = useState("");

    return (
        <>
            {openignTalk ? (
                <>
                    <OpeningSkip {...{ setOpeningTalk }} />
                    <OpeningTalk {...{ setOpeningTalk }} />
                </>
            ) : (
                <Header {...{ ward, menu, setMenu, setShopBoxFlag }} />
            )}
            {menu === "はなす" ? (
                <Talk {...{ ward, setMenu }} />
            ) : menu === "おしらせ" ? (
                <Status {...{ setMenu }} />
            ) : menu === "おみせ" ? (
                <ShopList {...{ ward, setMenu, setShopBoxFlag }} />
            ) : menu === "いどう" ? (
                <Move {...{ ward, setWard, setMenu }} />
            ) : menu === "じゅもん" ? (
                <Spell {...{ setScene, setMenu, setWard }} />
            ) : (
                ""
            )}
            <Area
                {...{
                    ward,
                    setWard,
                    openignTalk,
                    shopBoxFlag,
                    setShopBoxFlag,
                    setMenu,
                }}
            />
        </>
    );
};

export default Field;
