import Oide from "../assets/sound/kobeheoide_lq.mp3";
import KobeStrut from "../assets/sound/kobestrut_lq.mp3";
import Waai from "../assets/sound/waai_lq.mp3";

const changeSong = (song, audio, soundOn) => {
    console.log("音源読込");
    audio.current.pause();
    const songTitle = (() => {
        switch (song) {
            case "oide":
                return Oide;
            case "waai":
                return Waai;
            default:
                return KobeStrut;
        }
    })();

    audio.current.src = songTitle;
    if (soundOn) audio.current.play();
};

export default changeSong;
