import kumaZoom from '../assets/img/kuma-zoom.png';


const IntroRepeat = () => {
    const KumaZoom = () => <img src={kumaZoom} alt="ワタナベフラワー クマガイタツロウ" className="kuma-zoom" />
        return (
            <div className="intro-repeat">
            <p>そんなこと言わず<br />遊んでクレクレ～</p>
            <KumaZoom />
            </div>
        )
}

export default IntroRepeat;