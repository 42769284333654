import NormalBox from "./NormalBox";

const Status = ({ setMenu }) => {
    return (
        <NormalBox boxClassName={"talk-box"} h2Text="お知らせ">
            <>
                <p>神戸へおいで特設サイトが公開されたよ！</p>
                <p>
                    クマちゃんが訪れたお店や場所が、今後も追加される予定のつもり！
                </p>
                <div
                    className="cursor-triangle"
                    onClick={() => setMenu("")}
                ></div>
            </>
        </NormalBox>
    );
};

export default Status;
