import useWindowDimensions from "../hooks/useWindowDimensions";

const NormalBox = ({ children, boxClassName, h2Text }) => {
    const windowHeight = useWindowDimensions().height;
    const windowWidth = useWindowDimensions().width;

    const normalBoxStyle = {
        maxHeight: windowHeight,
        width: windowWidth * 0.9,
    };
    const normalBoxInnerStyle = {
        maxHeight: windowHeight - 180,
    };

    return (
        <div style={normalBoxStyle} className={"normal-box " + boxClassName}>
            <h2>
                <span>{h2Text}</span>
            </h2>
            <div className="box-inner" style={normalBoxInnerStyle}>
                {children}
            </div>
        </div>
    );
};

export default NormalBox;
