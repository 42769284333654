import { wards } from "../utils/constants";
import NormalBox from "./NormalBox";

const Move = ({ ward, setWard, setMenu }) => {
    const onClickedWardList = (props) => {
        //console.log(props.target.innerText);
        setWard(props.target.innerText);
        setMenu();
    };

    const selectedWord = (value) => {
        if (value === ward) return "selected";
    };
    const onClickWardKobe = () => {
        setWard();
        setMenu();
    };

    const wardList = wards().map((obj) => (
        <li
            className={selectedWord(obj.value)}
            onClick={onClickedWardList}
            key={obj.key}
        >
            {obj.value}
        </li>
    ));

    return (
        <>
            {wardList && (
                <NormalBox boxClassName={"talk-box"} h2Text="どの区に行く？">
                    <ul className="ward-select">
                        {wardList}
                        <li onClick={onClickWardKobe}>神戸市へ戻る</li>
                    </ul>
                </NormalBox>
            )}
        </>
    );
};

export default Move;
