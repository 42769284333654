import useCsvData from "../hooks/useCsvData";
import NormalBox from "./NormalBox";

const ShopList = ({ ward, setMenu, setShopBoxFlag }) => {
    const wardCsvData = useCsvData();

    return (
        <NormalBox boxClassName={"talk-box"} h2Text={ward + "のお店一覧"}>
            <ul>
                {wardCsvData &&
                    wardCsvData.map((data, index) => {
                        if (ward === data[6] && data[10] !== "1") {
                            return (
                                <li
                                    key={index}
                                    onClick={() => {
                                        setShopBoxFlag(data[1]);
                                        setMenu("");
                                    }}
                                >
                                    {data[1]}
                                </li>
                            );
                        } else {
                            return null;
                        }
                    })}
            </ul>
            <div className="cursor-triangle" onClick={() => setMenu("")}></div>
        </NormalBox>
    );
};

export default ShopList;
