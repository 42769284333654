import mapKobeBgTop from "../assets/img/map-kobe-bg-top.png";
import mapKobeBgBottom from "../assets/img/map-kobe-bg-bottom.png";
import mapKobeBgTarumi from "../assets/img/map-kobe-sp-bg-tarumi.png";
import mapKobeBgUmi from "../assets/img/map-kobe-sp-bg-umi.png";

const AreaBorder = ({ ward, areaHeight }) => {
    const styleAreaBgTop = {
        backgroundImage: `url(${mapKobeBgTop})`,
        height: areaHeight + "px",
    };
    const styleAreaBgBottom = {
        backgroundImage: `url(${mapKobeBgBottom})`,
        height: areaHeight + "px",
    };
    const styleAreaBgTarumi = {
        backgroundImage: `url(${mapKobeBgTarumi})`,
        height: areaHeight + "px",
    };
    const styleAreaBgUmi = {
        backgroundImage: `url(${mapKobeBgUmi})`,
        height: areaHeight + "px",
    };

    const AreaBorderTop = () => {
        return <div className="area-bg-top" style={styleAreaBgTop}></div>;
    };

    const AreaBorderBottom = () => {
        switch (ward) {
            case "西区":
            case "北区":
                return (
                    <div
                        className="area-bg-bottom"
                        style={styleAreaBgTop}
                    ></div>
                );
            case "東灘区":
            case "灘区":
            case "中央区":
            case "兵庫区":
            case "長田区":
            case "須磨区":
                return (
                    <div
                        className="area-bg-bottom"
                        style={styleAreaBgUmi}
                    ></div>
                );
            case "垂水区":
                return (
                    <div
                        className="area-bg-bottom"
                        style={styleAreaBgTarumi}
                    ></div>
                );
            default:
                return (
                    <div
                        className="area-bg-bottom"
                        style={styleAreaBgBottom}
                    ></div>
                );
        }
    };

    return (
        <>
            <AreaBorderTop />
            <AreaBorderBottom />
        </>
    );
};

export default AreaBorder;
