import ImgFace from "./ImgFace";
import NormalBox from "./NormalBox";

const AreaShopDetail = ({ shopBoxFlag, setShopBoxFlag, wardCsvData }) => {
    let shopData = "";
    wardCsvData.forEach((data) => {
        // console.log(data[1]);
        if (data[1] === shopBoxFlag) {
            //console.log(data);
            shopData = data;
        }
    });

    //console.log(shopData);
    if (shopData[0] !== "") {
        return (
            <NormalBox boxClassName={"talk-box"} h2Text={shopData[1]}>
                <ImgFace imgUrl="kuma" />
                {/* <h3>お店の名前 : {shopData[1]}</h3> */}
                <p>住所 : {shopData[0]}</p>
                <p>コメント : {shopData[2]}</p>
                {shopData[7] && (
                    <p>
                        外部リンク :
                        <a href={shopData[7]} rel="noreferrer" target="_blank">
                            {" "}
                            こちらから!!
                        </a>
                    </p>
                )}
                <div
                    className="cursor-triangle"
                    onClick={() => setShopBoxFlag("")}
                ></div>
            </NormalBox>
        );
    } else {
        return null;
    }
};

export default AreaShopDetail;
