import { useState } from "react";

const AreaDefault = ({ setWard }) => {
    const [selectWard, setSelectWard] = useState("");

    const onMouseEnterSvg = (event) => {
        const value = event.target.attributes.areaname.value;
        if (value !== selectWard) setSelectWard(value);
    };
    const onMouseLeaveSvg = (event) => {
        const value = event.target.attributes.areaname.value;
        if (value === selectWard) setSelectWard("");
    };

    return (
        <>
            <style>
                {`
                            #` +
                    selectWard +
                    ` {
                            color:red;
                            }
                        `}
            </style>
            <svg
                className="svg-area-select"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 1280 1536"
            >
                <rect
                    areaname="area-higashinadaku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("東灘区")}
                    x="1088"
                    y="640"
                    width="192"
                    height="896"
                />
                <rect
                    areaname="area-nadaku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("灘区")}
                    x="896"
                    y="640"
                    width="192"
                    height="896"
                />
                <rect
                    areaname="area-chuoku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("中央区")}
                    x="704"
                    y="832"
                    width="192"
                    height="704"
                />
                <rect
                    areaname="area-hyogoku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("兵庫区")}
                    x="512"
                    y="832"
                    width="192"
                    height="704"
                />
                <rect
                    areaname="area-nagataku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("長田区")}
                    x="384"
                    y="832"
                    width="128"
                    height="704"
                />
                <rect
                    areaname="area-sumaku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("須磨区")}
                    x="192"
                    y="832"
                    width="192"
                    height="704"
                />
                <rect
                    areaname="area-tarumiku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("垂水区")}
                    x="0"
                    y="832"
                    width="192"
                    height="704"
                />
                <rect
                    areaname="area-nishiku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("西区")}
                    x="0"
                    y="128"
                    width="512"
                    height="704"
                />
                <polygon
                    areaname="area-kitaku"
                    onMouseEnter={onMouseEnterSvg}
                    onMouseLeave={onMouseLeaveSvg}
                    onClick={() => setWard("北区")}
                    points="1088,128 1088,64 640,64 640,128 512,128 512,640 512,832 768,832 768,640 1216,640 1216,128 "
                />
            </svg>
            <div id="area-nishiku" className="area-text">
                西区
            </div>
            <div id="area-kitaku" className="area-text">
                北区
            </div>
            <div id="area-tarumiku" className="area-text">
                垂水区
            </div>
            <div id="area-sumaku" className="area-text">
                須磨区
            </div>
            <div id="area-nagataku" className="area-text">
                長田区
            </div>
            <div id="area-hyogoku" className="area-text">
                兵庫区
            </div>
            <div id="area-chuoku" className="area-text">
                中央区
            </div>
            <div id="area-nadaku" className="area-text">
                灘区
            </div>
            <div id="area-higashinadaku" className="area-text">
                東灘区
            </div>
        </>
    );
};

export default AreaDefault;
