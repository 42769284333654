import changeSong from "../utils/changeSong";

const SoundSelect = ({ audio, setScene, soundOn, setSoundOn }) => {
    const bgmOn = () => {
        setSoundOn(true);
        changeSong("oide", audio, soundOn);
        audio.current.play();
        setScene("intro");
    };

    const bgmOff = () => {
        setSoundOn(false);
        setScene("intro");
    };

    return (
        <div className="normal-box sound-select-box">
            <ul>
                <li onClick={bgmOn}>BGM On</li>
                <li onClick={bgmOff}>BGM Off</li>
            </ul>
        </div>
    );
};

export default SoundSelect;
