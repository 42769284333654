import faceMusa from '../assets/img/face-musa.png'
import faceIkuro from '../assets/img/face-ikuro.png'
import faceKuma from '../assets/img/face-kuma.png'

const ImgFace = ({ imgUrl, imgUrl2, imgAlt }) => {

    const Img = ({ url, alt }) => {
        switch (url) {
            case 'musa' :
                return <img src={faceMusa} alt={alt} />
            case 'ikuro' :
                return <img src={faceIkuro} alt={alt} />
            case 'kuma' :
                return <img src={faceKuma} alt={alt} />
            default:
                return null
        }
    }

    return (
        <div className="img-face">
            <Img url={imgUrl} alt={imgAlt} />
            {imgUrl2 &&
                <Img url={imgUrl2} alt={imgAlt} />
            }
        </div>
    )
}
export default ImgFace;