import ImgFace from "./ImgFace";
import TalkText from "./TalkText";
import NormalBox from "./NormalBox";

const Talk = ({ ward, setMenu }) => {
    const h2Text = ward ?? "神戸";

    return (
        <NormalBox boxClassName={"talk-box"} h2Text={h2Text + "においで"}>
            <ImgFace imgUrl="kuma" />
            <TalkText {...{ ward, setMenu }} />
        </NormalBox>
    );
};

export default Talk;
