import { useState } from "react";
import ImgFace from "./ImgFace";
import NormalBox from "./NormalBox";

const OpeningTalk = ({ setOpeningTalk }) => {
    const [opengTalkNum, setOpengTalkNum] = useState(0);

    const clickAction = () => {
        if (openingTalkText[opengTalkNum].end) {
            setOpeningTalk(false);
            setOpengTalkNum(0);
        } else {
            setOpengTalkNum(opengTalkNum + 1);
        }
    };

    return (
        <NormalBox
            boxClassName={"talk-box openig-talk-box"}
            h2Text={openingTalkText[opengTalkNum].name}
        >
            <ImgFace
                imgUrl={openingTalkText[opengTalkNum].img}
                imgUrl2={openingTalkText[opengTalkNum].img2}
            />
            {openingTalkText[opengTalkNum].html}
            <div className="cursor-triangle" onClick={clickAction}></div>
        </NormalBox>
    );
};

const openingTalkText = [
    {
        name: "ムサ",
        img: "musa",
        html: (
            <p>
                あああああああああああああああああああああああああああああ～～～～～～っ!!
            </p>
        ),
    },
    {
        name: "ムサ",
        img: "musa",
        html: (
            <p>
                「神戸へおいで 特設ページ」が始まっているのに…
                <strong>
                    『神戸を代表するアーティストになるべく神戸市の全9区に実際に住んでみる』
                </strong>
                という破天荒な企画に挑戦したクマちゃんが…まだ来ていない！
            </p>
        ),
    },
    {
        name: "イクロー",
        img: "ikuro",
        html: (
            <p>
                せ～のっ！って言ったらみんなで
                <br />
                「く～まちゃん！」って呼んでください!!
            </p>
        ),
    },
    {
        name: "ムサ",
        img: "musa",
        html: <p>せ～～のっ！</p>,
    },
    {
        name: "ムサ＆イクロー",
        img: "musa",
        img2: "ikuro",
        html: <p>く～まちゃ～ん!!</p>,
    },
    {
        name: "クマガイタツロウ",
        img: "kuma",
        html: <p>いや、おるよ。</p>,
    },
    {
        name: "ムサ",
        img: "musa",
        html: <p>い～～～たぁ～～～！</p>,
    },
    {
        name: "クマガイタツロウ",
        img: "kuma",
        html: (
            <p>
                いや、最初からおんねん。この企画やってたのは僕やから。
                <br />
                もっと言えば最初からなんかこの文章の後ろでウロウロしているから。ドット絵になった僕が。
            </p>
        ),
    },
    {
        name: "ムサ",
        img: "musa",
        html: <p>8bitだけに、突っ込みも荒いですね！</p>,
    },
    {
        name: "クマガイタツロウ",
        img: "kuma",
        html: <p>いやなんか上手い事言うた風やけど、別に上手くないねん。</p>,
    },
    {
        name: "ムサ",
        img: "musa",
        html: <p>　</p>,
    },
    {
        name: "クマガイタツロウ",
        img: "kuma",
        html: <p>なんか喋れや！</p>,
    },
    {
        name: "イクロー",
        img: "ikuro",
        html: (
            <p>
                この企画はワタナベフラワーのボーカルクマちゃんが、約1年をかけて神戸市全9区に本当に住んで、そこに住む人たちと交流を深めつつ街の魅力を再発見したり、区長と対談したり、美味しいもの食べたり、YouTubeで配信したり、道行く知らないおばちゃんから通りすがりに「応援してるよ!」といきなり昆布をもらったりした感動企画でした。多分。
            </p>
        ),
    },
    {
        name: "クマガイタツロウ",
        img: "kuma",
        html: (
            <p>
                多分ちゃうねん！ホンマにやってん！昆布のくだりもホンマやねん！お前ら簡単に言うけどな、思い付きで始めたは良いものの、それはもう大変な
            </p>
        ),
    },
    {
        name: "イクロー",
        img: "ikuro",
        html: (
            <p>
                というわけで、お世話になったお店や楽しかった事を、この特設ページでどんどん紹介していけたらと思います！
            </p>
        ),
    },
    {
        name: "ムサ＆イクロー",
        img: "musa",
        img2: "ikuro",
        html: <p>それでは、どうぞ！</p>,
    },
    {
        name: "クマガイタツロウ",
        img: "kuma",
        html: <p>ちょ、待って</p>,
    },
    {
        name: "ムサ＆イクロー",
        img: "musa",
        img2: "ikuro",
        html: <p>　</p>,
    },
    {
        name: "クマガイタツロウ",
        img: "kuma",
        html: <p>なんか喋れや！</p>,
        end: true,
    },
];

export default OpeningTalk;
