import { useState, useEffect } from "react";
import Csv from "../assets/csv/data.csv";
import { readString } from "react-papaparse";

const useCsvData = () => {
    const [csvData, setCsvData] = useState();

    useEffect(() => {
        const config = {
            worker: true,
            complete: (results) => {
                setCsvData(results.data);
                //return csvData;
                console.log(results.data);
            },
        };

        function getCsvData(dataPath) {
            const request = new XMLHttpRequest();
            request.addEventListener("load", (event) => {
                const response = event.target.responseText;
                readString(response, config);
            });
            request.open("GET", dataPath, true);
            request.send();
        }

        getCsvData(Csv);
    }, []);

    return csvData;
};

export default useCsvData;
