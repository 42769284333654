import { useState, useRef, useEffect } from "react";
import useResizeObserver from "../hooks/useResizeObserver";
import AreaImg from "./AreaImg";
import AreaDefault from "./AreaDefault";
import AreaShops from "./AreaShops";
import AreaShopDetail from "./AreaShopDetail";
import AreaBorder from "./AreaBorder";
import useCsvData from "../hooks/useCsvData";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Area = ({
    ward,
    setWard,
    openignTalk,
    shopBoxFlag,
    setShopBoxFlag,
    setMenu,
}) => {
    const [areaHeight, setAreaHeight] = useState(0);
    const [characterContaner, setCharacterContaner] = useState("inherit");
    const ref = useRef(null);
    const wardCsvData = useCsvData();
    const windowHeight = useWindowDimensions().height;
    // const onloadImg = (props) => {
    //     setAreaHeight(props.target.y);
    //     console.log('setAreaHeight:'+props.target.y);
    // }

    const onloadImgReload = () => {
        setAreaHeight(ref.current.offsetTop);
        //console.log('ref.current.offsetTop'+ref.current.offsetTop);
    };

    useResizeObserver([ref], onloadImgReload);

    useEffect(() => {
        if (areaHeight !== 0) setCharacterContaner("relative");
    }, [areaHeight]);

    const maxWindowsHeight = () => {
        return windowHeight > 960 ? 960 : windowHeight;
    };

    const styleAreaBox = {
        maxHeight: maxWindowsHeight(),
    };
    const styleCharacterContainer = {
        position: characterContaner,
        maxHeight: maxWindowsHeight(),
    };

    return (
        <>
            {ward && (
                <AreaShopDetail
                    {...{ shopBoxFlag, setShopBoxFlag, wardCsvData }}
                />
            )}
            <div className="area-box" style={styleAreaBox}>
                <div
                    ref={ref}
                    className="character-contaner"
                    style={styleCharacterContainer}
                >
                    {!openignTalk &&
                        (!ward ? (
                            <AreaDefault {...{ setWard }} />
                        ) : (
                            <AreaShops
                                {...{
                                    ward,
                                    shopBoxFlag,
                                    setShopBoxFlag,
                                    wardCsvData,
                                    setMenu,
                                }}
                            />
                        ))}
                    <AreaImg {...{ ward, styleAreaBox }} />
                </div>
                <AreaBorder {...{ ward, areaHeight }} />
            </div>
        </>
    );
};

export default Area;
