const OpeningSkip = ({ setOpeningTalk }) => {
    return (
        <header>
            <div className="main-menu">
                <h2>コマンド</h2>
                <ul>
                    <li onClick={() => setOpeningTalk(false)}>スキップ</li>
                </ul>
            </div>
        </header>
    );
};

export default OpeningSkip;
